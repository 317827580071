import {ChangeDetectionStrategy, Component} from '@angular/core';
import {HeaderService} from '@app-services';

@Component({
  selector: 'app-error-page',
  templateUrl: './error-page.component.html',
  styleUrl: './error-page.component.scss',
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class ErrorPageComponent {
  constructor(private headerService: HeaderService) {
    this.headerService.setShowHeader(true);
  }
}
