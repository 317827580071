<div class="header">
  <img class="branding-image" [src]="brandLogoSrc$ | async" />
  <div class="user-wrapper" *ngIf="hasUser">
    <i class="fas fa-user"></i>
    <span>{{ user.firstName }}&nbsp;{{ user.lastName }}</span>
  </div>
  <div class="header-right">
    <button *ngIf="hasUser" (click)="onLogout()" class="btn primary" mat-raised-button>
      {{ 'Overview.LogOut' | translate }}
    </button>
    <div *ngIf="selectedLang" class="translation-wrapper">
      <div class="flag-wrapper">
        <img
          (click)="
            dropdownFlags.style.visibility =
              dropdownFlags.style.visibility === 'hidden' ? 'visible' : 'hidden'
          "
          src="/assets/flags/{{ selectedLang }}.png"
          alt="language"
        />
        <div #dropdownFlags class="fake-popup" style="visibility: hidden">
          <div class="option-flag">
            <img
              (click)="
                onSetLang('en'); dropdownFlags.style.visibility = 'hidden'
              "
              src="/assets/flags/en.png"
            />
          </div>
          <div class="option-flag">
            <img
              (click)="
                onSetLang('de'); dropdownFlags.style.visibility = 'hidden'
              "
              src="/assets/flags/de.png"
            />
          </div>
        </div>
      </div>
    </div>
  </div>
</div>
