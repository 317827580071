import {ChangeDetectionStrategy, ChangeDetectorRef, Component, EventEmitter, forwardRef, Output,} from '@angular/core';
import {ControlValueAccessor, FormControl, FormGroup, NG_VALUE_ACCESSOR, Validators,} from '@angular/forms';
import * as moment from 'moment/moment';
import {DateRangePickerModel} from '../../models/DateRangePicker.model';

@Component({
  selector: 'app-date-range-picker',
  templateUrl: './date-range-picker.component.html',
  styleUrl: './date-range-picker.component.scss',
  changeDetection: ChangeDetectionStrategy.OnPush,
  providers: [
    {
      provide: NG_VALUE_ACCESSOR,
      multi: true,
      useExisting: forwardRef(() => DateRangePickerComponent),
    },
  ],
})
export class DateRangePickerComponent implements ControlValueAccessor {
  @Output() public rangeChanged: EventEmitter<DateRangePickerModel> =
    new EventEmitter<DateRangePickerModel>();

  public range = new FormGroup({
    start: new FormControl<moment.Moment | null>(null, Validators.required),
    end: new FormControl<moment.Moment | null>(null, Validators.required),
  });

  private onChanged: (data: DateRangePickerModel) => void;
  private onTouched: () => void;

  constructor(private changeRef: ChangeDetectorRef) {}

  /**
   * Subscribe to date picker changes to get data again
   * @returns void
   */
  public subscribeToDatePickerChanges(): void {
    const data: DateRangePickerModel = {
      from: this.range.controls.start.value,
      to: this.range.controls.end.value,
    };
    this.onChanged(data);
    this.onTouched();
    this.rangeChanged.emit(data);
  }
  public registerOnChange(fn: (data: DateRangePickerModel) => void): void {
    this.onChanged = fn;
  }

  public registerOnTouched(fn: () => void): void {
    this.onTouched = fn;
  }

  public writeValue(newValue: DateRangePickerModel): void {
    if (newValue) {
      this.range.controls.start.setValue(newValue.from);
      this.range.controls.end.setValue(newValue.to);
    }
    this.changeRef.detectChanges();
  }

  public setDisabledState(isDisabled: boolean): void {
    if (isDisabled) {
      this.range.disable();
    } else {
      this.range.enable();
    }
    this.changeRef.detectChanges();
  }
}
