import {ChangeDetectionStrategy, ChangeDetectorRef, Component, OnInit,} from '@angular/core';
import {User} from '@app-models';
import {AuthService, BrandingService} from '@app-services';
import {StorageKeys} from '@app-enums';
import {TranslateService} from '@ngx-translate/core';

@Component({
  selector: 'app-header',
  templateUrl: './header.component.html',
  styleUrl: './header.component.scss',
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class HeaderComponent implements OnInit {
  public user: User;
  protected hasUser = false;
  public selectedLang: string;

  protected brandLogoSrc$ = this.brandingService.getLogoUrl();

  constructor(
    private authService: AuthService,
    private translateService: TranslateService,
    private changeDetectorRef: ChangeDetectorRef,
    private brandingService: BrandingService
  ) {
    this.selectedLang = this.translateService.currentLang;
  }

  ngOnInit() {
    this.authService.getUser().subscribe((user) => {
      this.user = user;
      this.hasUser = !!user.id;
      this.changeDetectorRef.detectChanges();
    });
  }

  /**
   * Attempt logout
   *
   * @return Promise<void>
   */
  public async onLogout(): Promise<void> {
    await this.authService.logout();
  }

  /**
   * Set selected Language
   *
   * @param lang string
   */
  public onSetLang(lang: string): void {
    this.translateService.use(lang);
    this.translateService.setDefaultLang(lang);
    this.selectedLang = lang;
    localStorage.setItem(StorageKeys.LANGUAGE, lang);
  }
}
