import {NgModule} from '@angular/core';
import {CommonModule} from '@angular/common';
import {MaterialModule} from './modules/material/material.module';
import {HttpClientModule} from '@angular/common/http';
import {TranslateModule} from '@ngx-translate/core';
import {ErrorPageComponent} from './components/error-page/error-page.component';
import {LoaderComponent} from './components/loader/loader.component';
import {MatProgressSpinnerModule} from '@angular/material/progress-spinner';
import {DateRangePickerComponent} from './components/date-range-picker/date-range-picker.component';
import {FormsModule, ReactiveFormsModule} from '@angular/forms';

const modules = [
  CommonModule,
  TranslateModule,
  MaterialModule,
  HttpClientModule,
  MatProgressSpinnerModule,
  FormsModule,
  ReactiveFormsModule,
];

@NgModule({
  declarations: [ErrorPageComponent, LoaderComponent, DateRangePickerComponent],
  imports: [...modules],
  exports: [...modules, LoaderComponent, DateRangePickerComponent],
})
export class SharedModule {
}
