import {NgModule} from '@angular/core';
import {MatButtonModule} from '@angular/material/button';
import {MatFormField, MatFormFieldModule} from '@angular/material/form-field';
import {
  MatDatepickerModule,
  MatDatepickerToggle,
  MatDateRangeInput,
  MatDateRangePicker,
  MatEndDate,
  MatStartDate,
} from '@angular/material/datepicker';
import {MatInputModule} from '@angular/material/input';
import {MatTooltipModule} from '@angular/material/tooltip';
import {MatIconModule} from '@angular/material/icon';
import {MatSortModule} from '@angular/material/sort';
import {MatNativeDateModule} from '@angular/material/core';
import {MatTableModule} from '@angular/material/table';
import {MatPaginatorModule} from '@angular/material/paginator';
import {MatSelectModule} from '@angular/material/select';
import {MatMenuModule} from '@angular/material/menu';

const materialModules = [
  MatButtonModule,
  MatFormField,
  MatDateRangeInput,
  MatFormFieldModule,
  MatStartDate,
  MatEndDate,
  MatDateRangePicker,
  MatDatepickerToggle,
  MatInputModule,
  MatTooltipModule,
  MatIconModule,
  MatDatepickerModule,
  MatSortModule,
  MatNativeDateModule,
  MatTableModule,
  MatPaginatorModule,
  MatSelectModule,
  MatMenuModule,
];

@NgModule({
  declarations: [],
  imports: [...materialModules],
  exports: [...materialModules],
})
export class MaterialModule {
}
