<mat-form-field appearance="outline" color="accent">
  <mat-label>{{ 'Overview.EnterRange' | translate }}</mat-label>
  <mat-date-range-input [formGroup]="range" [rangePicker]="picker">
    <input
      matStartDate
      formControlName="start"
      placeholder="Start date"
      (dateChange)="subscribeToDatePickerChanges()"
    />
    <input
      matEndDate
      formControlName="end"
      placeholder="End date"
      (dateChange)="subscribeToDatePickerChanges()"
    />
  </mat-date-range-input>
  <!--      <mat-hint>MM/DD/YYYY – MM/DD/YYYY</mat-hint>-->
  <mat-datepicker-toggle matIconSuffix [for]="picker"></mat-datepicker-toggle>
  <mat-date-range-picker #picker></mat-date-range-picker>
  <mat-error>{{ 'Overview.InvalidDate' | translate }}</mat-error>
  @if (range.controls.start.hasError("matStartDateInvalid")) {
    <mat-error>{{ 'Overview.InvalidStartDate' | translate }}</mat-error>
  }
  @if (range.controls.end.hasError("matEndDateInvalid")) {
    <mat-error>{{ 'Overview.InvalidEndDate' | translate }}</mat-error>
  }
</mat-form-field>
