import {ChangeDetectionStrategy, Component, Input} from '@angular/core';
import {BehaviorSubject} from 'rxjs';
import {LoaderService} from '@app-services';

@Component({
  selector: 'app-loader',
  templateUrl: './loader.component.html',
  styleUrl: './loader.component.scss',
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class LoaderComponent {
  public showLoader$ = new BehaviorSubject<boolean>(false);

  @Input('loaderSingleton') public loaderSingleton: boolean;

  constructor(private loaderService: LoaderService) {
    this.loaderService.showLoader$.subscribe((showLoader) => {
      this.showLoader$.next(showLoader);
    });
  }
}
